// BG Images
export const BG_COVER = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/cover.jpg?updatedAt=1698741034566`;
export const BG_FOOTER = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/footer.jpg?updatedAt=1698741035325`;
export const BG_COUNTING_DOWN = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/counting.jpg?updatedAt=1698741035012`;
export const BG_INSTAGRAM_FILTER = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/filter.jpg?updatedAt=1699341067527`;
export const BG_GUEST_INFO = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/cover.jpg?updatedAt=1698741034566`;
export const BG_CONFIRMATION = BG_GUEST_INFO;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/counting.jpg?updatedAt=1698741035012`;

// Welcoming Image
export const IMG_PHOTO_1 = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/w1.jpg?updatedAt=1698741039175`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/w2.jpg?updatedAt=1698741039091`;
export const IMG_PHOTO_3 = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/w3.jpg?updatedAt=1698741039315`;

// Wedding Detail Image
export const IMG_AKAD = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/w1.jpg?updatedAt=1698741039175`;
export const IMG_RECEPTION = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/w1.jpg?updatedAt=1698741039175`;
export const IMG_DRESSCODE = `https://user-images.githubusercontent.com/10141928/169198604-51a5748c-b5ca-4a7b-8576-d9bf6945e0d6.jpg`;

// Couple Image
export const IMG_COUPLE = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/pengantin-new.jpeg?updatedAt=1699084610603`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/168402483-62cc3dd6-b5ba-4939-ac3d-cc136bd9bdbe.jpg`;
export const IMG_MAN = `https://user-images.githubusercontent.com/10141928/159520655-4e91aa6f-ba20-4f74-912a-62a0f361fc75.jpg`;
export const IMG_GIRL = `https://user-images.githubusercontent.com/10141928/159520628-f6c02869-e08e-4720-a4bd-095799e79cdf.jpg`;
export const IMG_WELCOMING_SECTION = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/welcome.jpg?updatedAt=1698741039450`;
export const IMG_WISHES = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/wishes.jpg?updatedAt=1698741035007`;

// Cover Gallery Image
export const IMG_GALLERY_1 = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/g1.jpg?updatedAt=1698741035381`;
export const IMG_GALLERY_2 = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/g2.jpg?updatedAt=1698741035246`;
export const IMG_GALLERY_COVER = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/g3.jpg?updatedAt=1698741035321`;

// Logo Section
export const IMG_LOGO = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/logo.png?updatedAt=1698741034337`;
export const IMG_LOGO_INVERT = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/logo-invert.png?updatedAt=1698741034562`;

/**
 * Image Flower Assets
 * @important - IF YOU WANT TO OVERRIDE THIS ASSETS,
 * MAKE SURE YOU REPLACE WITH SAME RESOLUTION
 */
export const ASSETS_FLOWER_LEFT = `https://user-images.githubusercontent.com/10141928/167748673-62d2c7c6-9064-49f2-bcc0-9be00aaf510a.png`;

/**
 * Invitato Image Assets
 * @important - DON'T CHANGE THIS CODE
 */
export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};
